import React from 'react';

const Footer: React.FC = () => {
	return (
		<footer className="bottom-0 w-full py-6 px-4 border-t border-gray-100 mt-auto">
			<p className="text-gray-500 text-sm">
				© {new Date().getFullYear()}. Litto. All rights reserved in Split,
				Croatia.
			</p>
		</footer>
	);
};

export default Footer;
