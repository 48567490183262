import { get, set } from 'litto-lib/utils';

import { initialUserState, IUserState } from 'context/UserContext';

export function setUserState(userState: IUserState): void {
	set({
		key: 'userState',
		value: userState
	});
}

export function getUserState(): IUserState {
	try {
		const storedUser: any = get({ key: 'userState' });
		return storedUser || initialUserState;
	} catch (err) {
		return initialUserState;
	}
}
