import { SvgMenuBtn, SvgPhoto } from 'litto-lib/assets';
import {
	Header,
	HeaderLink,
	PlainHeader
} from 'litto-lib/components/shared/Header';
import { useOnClickOutside } from 'litto-lib/hooks';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import Footer from './Footer';
import { useGetAdminQuery } from 'services/admin/getAdmin';

interface ILayoutProps {
	children: React.ReactNode;
}

export const NAV_ITEMS = [
	{
		label: 'Dashboard',
		path: '/'
	},
	{
		label: 'Reservations',
		path: '/reservations'
	},
	{
		label: 'Listings',
		path: '/listings'
	},
	{
		label: 'Calendar',
		path: '/calendar'
	},
	{
		label: 'Support',
		path: '/support'
	}
];

const withoutLayout = ['/login', '/profile'];
const withPlainLayout = ['/reservations/[id]'];
const withUnauthenticatedLayout = ['/change-password'];

const Layout: React.FC<ILayoutProps> = ({ children }) => {
	const [navOpen, setNavOpen] = React.useState(false);
	const menuBtnRef = React.useRef(null);
	useOnClickOutside(menuBtnRef, () => setNavOpen(false));

	const router = useRouter();
	const { data: user } = useGetAdminQuery('me', {
		enabled:
			!withoutLayout.includes(router.pathname) &&
			!withUnauthenticatedLayout.includes(router.pathname)
	});
	if (withUnauthenticatedLayout.includes(router.pathname)) {
		return (
			<div className="min-h-screen flex flex-col">
				<PlainHeader hideBackButton />
				<main className="py-6 px-5 md:py-13 md:px-36 h-full">{children}</main>
				<Footer />
			</div>
		);
	} else if (withPlainLayout.includes(router.pathname)) {
		return (
			<div className="min-h-screen flex flex-col">
				<PlainHeader />
				<main className="py-6 px-5 md:py-13 md:px-36 h-full">{children}</main>
				<Footer />
			</div>
		);
	} else if (withoutLayout.includes(router.pathname)) {
		return <>{children}</>;
	}

	return (
		<div className="min-h-screen flex flex-col">
			<Header
				slotCenter={
					<nav
						onClick={() => setNavOpen(false)}
						className={`
							${navOpen ? '' : 'translate-x-full'}
							lg:translate-x-0 transition-transform list-none flex flex-col lg:flex-row inset-0 fixed lg:static h-full ml-auto lg:mx-auto max-w-sm lg:max-w-none bg-white z-20 shadow-lg lg:shadow-[none] space-y-4 pt-10 lg:pt-0 lg:space-y-0
						`}
					>
						{NAV_ITEMS.map(item => (
							<HeaderLink key={item.path} className="lg:mx-4" href={item.path}>
								{item.label}
							</HeaderLink>
						))}
					</nav>
				}
				slotRight={
					<div className="flex ml-auto gap-4">
						<Link href="/profile">
							<a className="flex overflow-hidden rounded-full hover:opacity-50 transition-opacity relative w-10 h-10">
								{user?.avatar?.avatarUrl ? (
									<Image
										src={user.avatar.avatarUrl}
										alt="Profile"
										width={40}
										height={40}
									/>
								) : (
									<div className="bg-gray-100 absolute inset-0 flex items-center justify-center">
										<SvgPhoto className="w-4" />
									</div>
								)}
							</a>
						</Link>
						<button
							ref={menuBtnRef}
							className="lg:hidden"
							onClick={() => setNavOpen(!navOpen)}
						>
							<SvgMenuBtn />
						</button>
					</div>
				}
			/>
			<main
				className={`${
					navOpen ? 'blur-lg' : ''
				}  lg:filter-none transition-all py-6 px-5 md:py-13 md:px-13 xl:px-36 h-full `}
			>
				{children}
			</main>
			<Footer />
		</div>
	);
};

export default Layout;
