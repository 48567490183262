
import { createAction } from './actions/actions';
import { IUserState } from '../context/UserContext';

export enum UserActions {
	UserStateInitialize = '[UserContext] UserStateInitalize',
}

export type UserActionTypes =
	| IUserStateInitializeAction

export const userReducer = (state: IUserState, action: UserActionTypes) => {
	switch (action.type) {
		case UserActions.UserStateInitialize: {
			return {
				...state
			};
		}
		default: {
			throw new Error('UserReducet: This reducer action does not exist');
		}
	}
};

interface IUserStateInitializeAction {
	type: typeof UserActions.UserStateInitialize;
	payload: IUserState;
}
export const userStateInitializeAction = createAction<
	IUserStateInitializeAction['payload']
>(UserActions.UserStateInitialize);
