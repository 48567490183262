// @todo odvojit UI ikone, content ikone i ilustracije u posebne fajlove

import SvgAirConditioning from './air_conditioning.svg';
import SvgAmericanExpress from './american_express_card.svg';
import SvgBabyBath from './baby_bath.svg';
import SvgBabyMonitor from './baby_monitor.svg';
import SvgBabySafetyGates from './baby_safety_gates.svg';
import SvgBabysitterRecommendations from './babysitter_recommendations.svg';
import SvgBakingSheet from './baking_sheet.svg';
import SvgBarbecueUtensils from './barbecue_utensils.svg';
import SvgBathtub from './bathtub.svg';
import SvgBeachEssentials from './beach_essentials.svg';
import SvgBeachFront from './beachfront.svg';
import SvgBedLinens from './bed_linens.svg';
import SvgBidet from './bidet.svg';
import SvgBikes from './bikes.svg';
import SvgBoardGames from './board_games.svg';
import SvgBoatSlip from './boat_slip.svg';
import SvgBodySoap from './body_soap.svg';
import SvgBreakfast from './breakfast.svg';
import SvgBuildingStaff from './building_staff.svg';
import SvgCableTV from './cable_tv.svg';
import SvgCancellationPolicy from './cancellation-policy.svg';
import SvgCarbonMonoxideAlarm from './carbon_monoxide_alarm.svg';
import SvgCeilingFan from './ceiling_fan.svg';
import SvgChangingTable from './changing_table.svg';
import SvgChldrenBooksAndToys from './children_books_and_toys.svg';
import SvgChildrenDinerware1 from './children_dinerware-1.svg';
import SvgChildrenDinerware from './children_dinerware.svg';
import SvgCleaningBeforeCheckout from './cleaning_before_checkout.svg';
import SvgCleaningProducts from './cleaning_products.svg';
import SvgClothingStorage from './clothing_storage.svg';
import SvgCoffeMaker from './coffee_maker.svg';
import SvgConditioner from './conditioner.svg';
import SvgCookingBasics from './cooking_basics.svg';
import SvgCrib from './crib.svg';
import SvgDedicatedWorspace from './dedicated_workspace.svg';
import SvgDinersClub from './diners_club_card.svg';
import SvgDiningTable from './dining_table.svg';
import SvgDiscover from './discover_card.svg';
import SvgDishesAndSilverware from './dishes_and_silverware.svg';
import SvgDishwasher from './dishwasher.svg';
import SvgDryer from './dryer.svg';
import SvgDryingRackForClothing from './drying_rack_for_clothing.svg';
import SvgElevator from './elevator.svg';
import SvgEntireHome from './entire-home.svg';
import SvgEssentials from './essentials.svg';
import SvgEthernetConnection from './ethernet_connection.svg';
import SvgEvCharger from './ev_charger.svg';
import SvgExtraPillowAndBlankets from './extra_pillow_and_blankets.svg';
import SvgFireExtinguisher from './fire_extinguisher.svg';
import SvgFirePit from './fire_pit.svg';
import SvgFireplaceGuards from './fireplace_guards.svg';
import SvgFirstAidKit from './first_aid_kit.svg';
import SvgFrame from './Frame 1.svg';
import SvgFreeParkingOnPremises from './free_parking_on_premises.svg';
import SvgFreeStreetParking from './free_street_parking.svg';
import SvgFreezer from './freezer.svg';
import SvgGameConsole from './game_console.svg';
import SvgGardenOrBackyard from './garden_or_backyard.svg';
import SvgGym from './gym.svg';
import SvgHairDryer from './hair_dryer.svg';
import SvgHangers from './hangers.svg';
import SvgHeating from './heating.svg';
import SvgHighChair from './high_chair.svg';
import SvgHotTub from './hot_tub.svg';
import SvgHotWater from './hot_water.svg';
import SvgWaterKettle from './hot_water_kettle.svg';
import SvgHouseRules from './house-rules.svg';
import SvgIndoorFireplace from './indoor_fireplace.svg';
import SvgIron from './iron.svg';
import SvgKayak from './kayak.svg';
import SvgKeurigCoffeeMachine from './keurig_coffee_machine.svg';
import SvgKey from './key.svg';
import SvgKitchen from './kitchen.svg';
import SvgLakeAccess from './lake_access.svg';
import SvgLaundromatNearby from './laundromat_nearby.svg';
import SvgLifebuoy from './lifebuoy.svg';
import SvgLogo from './litto-logo.svg';
import SvgLittoPromoIllustration from './litto_promo_illustration.svg';
import SvgLockbox from './lockbox.svg';
import SvgLockPad from './lockpad.svg';
import SvgLongStay from './long_stay.svg';
import SvgLongTermStaysAllowed from './long_term_stays_allowed.svg';
import SvgLuggageDropOff from './luggage_dropoff.svg';
import SvgLuggageDropoffAllowed from './luggage_dropoff_allowed.svg';
import SvgMaestroCard from './maestro_card.svg';
import SvgMasterCard from './master_card.svg';
import SvgMicrowave from './microwave.svg';
import SvgMiniFridge from './mini_fridge.svg';
import SvgMosquitoNet from './mosquito_net.svg';
import SvgNespresso from './nespresso.svg';
import SvgOutdoorDiningArea from './outdoor_dining_area.svg';
import SvgOutdoorFurniture from './outdoor_furniture.svg';
import SvgOutdoorShower from './outdoor_shower.svg';
import SvgOutletCovers from './outlet_covers.svg';
import SvgOven from './oven.svg';
import SvgPackAndPlayTravelCrib from './pack_and_play_travel_crib.svg';
import SvgPaidParkingoffPremises from './paid_parking_off_premises.svg';
import SvgPaidParkingOnPremises from './paid_parking_on_premises.svg';
import SvgPartiesAllowed from './parties_allowed.svg';
import SvgPatioOrBalcony from './patio_or_balcony.svg';
import SvgPetsAllowed from './pets_allowed.svg';
import SvgPiano from './piano.svg';
import SvgPingPongTable from './ping_pong_table.svg';
import SvgPlane from './plane.svg';
import SvgPocketWifi from './pocket_wifi.svg';
import SvgPool from './pool.svg';
import SvgPoolTable from './pool_table.svg';
import SvgPortableFans from './portable_fans.svg';
import SvgPourOverCoffee from './pour_over_coffee.svg';
import SvgPoweredByStripe from './powered_by_stripe.svg';
import SvgPrivateEntrace from './private_entrace.svg';
import SvgPropertyType from './property-type.svg';
import SvgRecordPlayer from './record_player.svg';
import SvgRefrigerator from './refrigerator.svg';
import SvgRiceMaker from './rice_maker.svg';
import SvgRoomDarkeningShades from './room_darkening_shades.svg';
import SvgSafe from './safe.svg';
import SvgSauna from './sauna.svg';
import SvgSelfCheckingPossible from './self_checkin_possible.svg';
import SvgShampoo from './shampoo.svg';
import SvgShowerGel from './shower_gel.svg';
import SvgSingleLevelCode from './single_level_home.svg';
import SvgSkiInSkiOut from './ski_in_ski_out.svg';
import SvgSmokeAlarm from './smoke_alarm.svg';
import SvgSmokingAllowed from './smoking_allowed.svg';
import SvgSoundSystem from './sound_system.svg';
import SvgStove from './stove.svg';
import SvgStripe from './stripe.svg';
import SvgTableCornerGuards from './table_corner_guards.svg';
import SvgToaster from './toaster.svg';
import SvgTransferOut from './transfer_out.svg';
import SvgTrashCompactor from './trash_compactor.svg';
import SvgTV from './tv.svg';
import SvgUnionPay from './union_pay_card.svg';
import SvgUserCheck from './user_check.svg';
import SvgUsers2 from './users2.svg';
import SvgVisa from './visa_card.svg';
import SvgWallet2 from './wallet2.svg';
import SvgWasher from './washer.svg';
import SvgWaterfront from './waterfront.svg';
import SvgWifi from './wifi.svg';
import SvgWindowGuards from './window_guards.svg';
import SvgWineGlasses from './wine_glasses.svg';

export {
	SvgLogo,
	SvgAirConditioning,
	SvgBabyBath,
	SvgBabyMonitor,
	SvgBabySafetyGates,
	SvgBabysitterRecommendations,
	SvgBakingSheet,
	SvgBarbecueUtensils,
	SvgBathtub,
	SvgBeachEssentials,
	SvgBeachFront,
	SvgBedLinens,
	SvgBidet,
	SvgBikes,
	SvgBoardGames,
	SvgBoatSlip,
	SvgBodySoap,
	SvgBreakfast,
	SvgCableTV,
	SvgCarbonMonoxideAlarm,
	SvgCeilingFan,
	SvgChangingTable,
	SvgChldrenBooksAndToys,
	SvgChildrenDinerware1,
	SvgChildrenDinerware,
	SvgCleaningBeforeCheckout,
	SvgCleaningProducts,
	SvgClothingStorage,
	SvgCoffeMaker,
	SvgConditioner,
	SvgCookingBasics,
	SvgCrib,
	SvgDedicatedWorspace,
	SvgDiningTable,
	SvgDishesAndSilverware,
	SvgDishwasher,
	SvgDryer,
	SvgDryingRackForClothing,
	SvgEthernetConnection,
	SvgEvCharger,
	SvgExtraPillowAndBlankets,
	SvgFireExtinguisher,
	SvgFirePit,
	SvgFireplaceGuards,
	SvgFirstAidKit,
	SvgFrame,
	SvgFreeParkingOnPremises,
	SvgFreeStreetParking,
	SvgFreezer,
	SvgGameConsole,
	SvgGardenOrBackyard,
	SvgGym,
	SvgHairDryer,
	SvgHangers,
	SvgHeating,
	SvgHighChair,
	SvgHotTub,
	SvgWaterKettle,
	SvgHotWater,
	SvgIndoorFireplace,
	SvgIron,
	SvgKayak,
	SvgKeurigCoffeeMachine,
	SvgKitchen,
	SvgLakeAccess,
	SvgLaundromatNearby,
	SvgLongTermStaysAllowed,
	SvgLuggageDropoffAllowed,
	SvgMicrowave,
	SvgMiniFridge,
	SvgMosquitoNet,
	SvgNespresso,
	SvgOutdoorDiningArea,
	SvgOutdoorFurniture,
	SvgOutdoorShower,
	SvgOutletCovers,
	SvgOven,
	SvgPackAndPlayTravelCrib,
	SvgPaidParkingoffPremises,
	SvgPaidParkingOnPremises,
	SvgPartiesAllowed,
	SvgPatioOrBalcony,
	SvgPetsAllowed,
	SvgPiano,
	SvgPingPongTable,
	SvgPocketWifi,
	SvgPoolTable,
	SvgPool,
	SvgPortableFans,
	SvgPourOverCoffee,
	SvgPrivateEntrace,
	SvgRecordPlayer,
	SvgRefrigerator,
	SvgRiceMaker,
	SvgRoomDarkeningShades,
	SvgSafe,
	SvgSauna,
	SvgSelfCheckingPossible,
	SvgShampoo,
	SvgShowerGel,
	SvgSingleLevelCode,
	SvgSkiInSkiOut,
	SvgSmokeAlarm,
	SvgSmokingAllowed,
	SvgSoundSystem,
	SvgStove,
	SvgTableCornerGuards,
	SvgToaster,
	SvgTrashCompactor,
	SvgTV,
	SvgWasher,
	SvgWaterfront,
	SvgWifi,
	SvgWindowGuards,
	SvgWineGlasses,
	SvgLittoPromoIllustration,
	SvgCancellationPolicy,
	SvgPropertyType,
	SvgEntireHome,
	SvgHouseRules,
	SvgLifebuoy,
	SvgWallet2,
	SvgUsers2,
	SvgPlane,
	SvgStripe,
	SvgVisa,
	SvgUnionPay,
	SvgMasterCard,
	SvgAmericanExpress,
	SvgDiscover,
	SvgDinersClub,
	SvgMaestroCard,
	SvgPoweredByStripe,
	SvgTransferOut,
	SvgBuildingStaff,
	SvgElevator,
	SvgEssentials,
	SvgKey,
	SvgLockbox,
	SvgLockPad,
	SvgLongStay,
	SvgLuggageDropOff,
	SvgUserCheck
};
