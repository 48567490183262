import { Hydrate, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Cookies from 'js-cookie';
import { GridHelper, UrlChangeListener } from 'litto-lib/components/common';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';
import 'styles/index.scss';
// import "@fullcalendar/common/";
// import "@fullcalendar/daygrid/main.css";
// import "@fullcalendar/timegrid/main.css";
// import 'swiper/scss';
import 'swiper/scss/pagination';
import 'react-phone-input-2/lib/high-res.css';
import 'react-dates/initialize';
// import ReactGA4 from 'react-ga4';
import { ToastContainer } from 'react-toastify';

// import { CookieConsent, CookiesProvider } from 'components/shared';
import { CalendarContextProvider } from 'components/calendar/CalendarContext';
import Layout from 'components/layout';
import { configs } from 'configs';
import {
	// OrderProvider,
	// useOrderDispatchContext,
	// useOrderStateContext,
	UserProvider,
	useUserDispatchContext,
	useUserStateContext
} from 'context';

const { client: queryClient } = configs();

// const tagManagerArgs = {
// 	gtmId: process.env.NEXT_PUBLIC_GTM_KEY || ''
// };

// ReactGA4.initialize(tagManagerArgs.gtmId);

const MyApp = ({ Component, pageProps }: AppProps) => {
	const renderLayout = () => (
		<Layout>
			<Head>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1"
				></meta>
			</Head>
			<GridHelper />
			{/* <CookieConsent /> */}
			<Component {...pageProps} />
		</Layout>
	);

	return (
		<>
			<QueryClientProvider client={queryClient}>
				<Hydrate state={(pageProps as any).dehydratedState}>
					<UserProvider>
						<CalendarContextProvider>
							{/* <CookiesProvider> */}
							{/* <OrderProvider> */}
							<AppWithState>{renderLayout()}</AppWithState>
							<UrlChangeListener />
							<ReactQueryDevtools initialIsOpen={false} />
							<ToastContainer newestOnTop limit={1} />
							{/* </OrderProvider> */}
							{/* </CookiesProvider> */}
						</CalendarContextProvider>
					</UserProvider>
				</Hydrate>
			</QueryClientProvider>
		</>
	);
};

const AppWithState: React.FC<any> = ({ children }) => {
	const router = useRouter();
	const userDispatch = useUserDispatchContext();
	const { initContext } = useUserStateContext();

	React.useEffect(() => {
		initContext(userDispatch);
		const token = Cookies.get('token');
		if (!token && router.pathname !== '/change-password') {
			router.push('/login');
		}
	}, []);

	return <React.Fragment>{children}</React.Fragment>;
};

export default MyApp;
