import React from 'react';

import { IGetAccommodationRead } from 'services/accommodations/interfaces';

const CalendarContext = React.createContext<any>({});

export const CalendarContextProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const [listingsManagedByCM, setListingsManagedByCM] = React.useState<
	IGetAccommodationRead[] | undefined
	>();
	const context = {
        listingsManagedByCM,
        setListingsManagedByCM
    };

	return (
		<CalendarContext.Provider value={context}>
			{children}
		</CalendarContext.Provider>
	);
};

export const useCalendarContext = () =>{
    return React.useContext(CalendarContext)
}
