import SvgArrowIcon from './arrow-icon.svg';
import SvgArrowBack from './arrow_back.svg';
import SvgArrowRight from './arrow_right.svg';
import SvgCalendar from './calendar.svg';
import SvgCheckIcon from './check-icon.svg';
import SvgCheckinTime from './checkin_time.svg';
import SvgCheckoutTime from './checkout_time.svg';
import SvgClear from './clear.svg';
import SvgCloseIcon from './close-icon.svg';
import SvgDotsIcon from './dots-icon.svg';
import SvgEdit from './edit.svg';
import SvgExternalLink2 from './external-link-2.svg';
import SvgExternalLink from './external-link.svg';
import SvgFacebook from './facebook.svg';
import SvgFeatureIcon from './feature-item-icon.svg';
import SvgFilters from './filters.svg';
import SvgInstagram from './instagram.svg';
import SvgLinkedin from './linkedin.svg';
import SvgLock from './lock.svg';
import SvgMapMarker from './marker-map.svg';
import SvgMenuBtn from './menu-btn.svg';
import SvgNumberOfBaths from './number_of_baths.svg';
import SvgNumberOfBedrooms from './number_of_bedrooms.svg';
import SvgNumberOfBeds from './number_of_beds.svg';
import SvgNumberOfGuests from './number_of_guests.svg';
import SvgPhoto from './photo-icon.svg';
import SvgPin from './pin.svg';
import SvgSaveIcon from './save-icon.svg';
import SvgSearchIcon from './search-icon.svg';
import SvgSelectArrow from './select_arrows.svg';
import SvgShareIcon from './share-icon.svg';
import SvgUsers from './users.svg';
import SvgWallet from './wallet.svg';
import SvgYoutube from './youtube.svg';
import BlockedIcon from './blocked.svg';
import ICalIcon from './ical.svg';

export {
	SvgArrowBack,
	SvgArrowRight,
	SvgSelectArrow,
	SvgFeatureIcon,
	SvgCalendar,
	SvgDotsIcon,
	SvgShareIcon,
	SvgSaveIcon,
	SvgSearchIcon,
	SvgEdit,
	SvgCloseIcon,
	SvgClear,
	SvgMenuBtn,
	SvgCheckIcon,
	SvgArrowIcon,
	SvgCheckinTime,
	SvgCheckoutTime,
	SvgNumberOfBaths,
	SvgNumberOfBedrooms,
	SvgNumberOfBeds,
	SvgNumberOfGuests,
	SvgUsers,
	SvgWallet,
	SvgFilters,
	SvgPin,
	SvgExternalLink2,
	SvgExternalLink,
	SvgFacebook,
	SvgInstagram,
	SvgYoutube,
	SvgLinkedin,
	SvgMapMarker,
	SvgPhoto,
	SvgLock,
	BlockedIcon,
	ICalIcon
};
