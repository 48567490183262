import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';

import { IGetAdminResponse } from './interfaces';

export const getAdmin = async (adminId: string) => {
	return await axios
		.get<IGetAdminResponse>(`/api/v2/admin/administrators/${adminId}`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error('Error getting admin: ' + adminId, error);
			return Promise.reject(error);
		});
};

type UseGetTaxonsQueryOptions = UseQueryOptions<IGetAdminResponse, any, IGetAdminResponse>;

export function useGetAdminQuery(
	adminId: string,
	options?: UseGetTaxonsQueryOptions
) {
	return useQuery(
		['administrators', adminId],
		() => {
			return getAdmin(adminId);
		},
		options as any
	);
}
