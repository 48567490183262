import { useScrollLock } from 'litto-lib/hooks';
import { isMobileScreen } from 'litto-lib/utils';
import React, { useEffect, useRef, useState } from 'react';
import ModalPlugin from 'react-modal';

import { ModalFooter, ModalHeader } from '.';
import { ModalCustomScrollbars } from './ModalCustomScrollbars';

export interface IModalProps extends ModalPlugin.Props {
	className?: string;
	noPadding?: boolean;
	customWidth?: string;
	innerScrollSelector?: string;
	headerText?: string;
	maxHeight?: string;
	minHeight?: string;
	fullHeight?: boolean;
	onRequestClose?: () => void;
	noHeader?: boolean;
	isSideSheet?: boolean;
	hideFooter?: boolean;
	disableScrollShadow?: boolean;
}
interface IModalComposition {
	Footer: React.FC;
}

export const Modal: React.FC<IModalProps> & IModalComposition = ({
	children,
	className,
	customWidth,
	noPadding,
	innerScrollSelector,
	isOpen,
	onRequestClose,
	noHeader,
	headerText,
	minHeight,
	maxHeight = 'min(850px, 90vh)',
	fullHeight,
	isSideSheet = false,
	hideFooter = false,
	disableScrollShadow = false,
	...rest
}) => {
	const [scrollableMaxHeight, setScrollableMaxHeight] = useState('');
	const [scrollableMinHeight, setScrollableMinHeight] = useState('');

	const contentWrapRef = React.createRef<any>();

	if (fullHeight) {
		minHeight = '100vh - 30px';
	}

	useScrollLock<any | null>(
		innerScrollSelector !== undefined && isOpen,
		contentWrapRef,
		innerScrollSelector
	);

	const headerRef = useRef<HTMLDivElement>(null);
	const footerRef = useRef<HTMLDivElement>(null);

	const footer = React.Children.map(children, (child: any) => {
		if (child?.key && child?.key.includes('ModalFooter')) {
			return React.cloneElement(child);
		}
	});

	const hasFooter = (!!footer && footer?.length > 0) || !hideFooter;

	const getContentWrapperStyles = () => {
		let styles = 'h-full';
		if (noPadding) {
			return styles;
		}
		styles += ' px-5 md:px-12';
		styles += noHeader ? ' pt-6' : ' pt-3 md:pt-6';
		styles += !hasFooter ? ' pb-12' : ' pb-6';

		return styles;
	};

	const content = React.Children.map(children, (child: any) => {
		if (child?.key === null) {
			return (
				<div className={getContentWrapperStyles()}>
					{React.cloneElement(child)}
				</div>
			);
		}
	});

	useEffect(() => {
		const handleResize = () => {
			setTimeout(() => {
				setScrollableMaxHeight(getScrollableMaxHeight());
			}, 100);
		};

		if (typeof window !== 'undefined') {
			const isInApp = document.querySelector('#__next');
			ModalPlugin.setAppElement(isInApp ? '#__next' : '#root');
			setScrollableMaxHeight(getScrollableMaxHeight());
			window.addEventListener('resize', handleResize);
		}
		return () => {
			if (typeof window !== 'undefined') {
				window.removeEventListener('resize', handleResize);
			}
		};
	}, []);

	const afterOpenModal = () => {
		setScrollableMaxHeight(getScrollableMaxHeight());
		setScrollableMinHeight(getScrollableMinHeight());
	};

	const getOffsetHeight = () => {
		const headerOffset = (headerRef as any)?.current?.clientHeight || 0;
		const footerOffset = (footerRef as any)?.current?.clientHeight || 0;
		return headerOffset + footerOffset;
	};

	const getScrollableMinHeight = () => {
		const base = minHeight || '20vh';
		return `calc(${base} - ${getOffsetHeight()}px)`;
	};

	const getScrollableMaxHeight = () => {
		const maxHeightMobile =
			typeof window !== 'undefined' ? window.innerHeight - 40 + 'px' : '20vh';
		const base = isMobileScreen() ? maxHeightMobile : maxHeight;
		return `calc(${base} - ${getOffsetHeight()}px)`;
	};

	const sidesheetClassnames = `side-sheet !rounded-none !absolute top-0 right-0 h-screen`;

	return (
		(isOpen && (
			<ModalPlugin
				overlayClassName={`${
					isSideSheet ? 'side-sheet' : ''
				} z-[70] bg-black fixed top-0 left-0 bottom-0 right-0 bg-opacity-20 flex justify-center items-end md:items-center`}
				className={`
					${className ? className : ''}
					relative
					transition-all
					flex
					w-full flex-col
					overflow-hidden
					rounded-t-xl bg-white outline-none
					${customWidth ? customWidth : 'md:w-3/4 md:max-w-3xl'} 

					
					
					${isSideSheet ? sidesheetClassnames : 'md:rounded-xl'}
				 `}
				onRequestClose={onRequestClose}
				isOpen={isOpen}
				onAfterOpen={afterOpenModal}
				{...rest}
			>
				<div ref={headerRef}>
					{!noHeader && (
						<ModalHeader
							headerText={headerText}
							onRequestClose={onRequestClose}
						/>
					)}
				</div>
				{innerScrollSelector ? (
					<div
						ref={contentWrapRef}
						style={
							fullHeight
								? { height: scrollableMaxHeight }
								: {
										maxHeight: scrollableMaxHeight,
										minHeight: scrollableMinHeight
								  }
						}
					>
						{content}
					</div>
				) : (
					<ModalCustomScrollbars
						noHeader
						hasFooter={hasFooter}
						autoHeightMax={scrollableMaxHeight}
						autoHeightMin={scrollableMinHeight}
						disableShadow={disableScrollShadow}
					>
						{content}
					</ModalCustomScrollbars>
				)}
				{hasFooter && (
					<div
						ref={footerRef}
						className={`p-5 pt-3 md:border-none md:p-12 md:py-3`}
					>
						{footer}
					</div>
				)}
			</ModalPlugin>
		)) || <React.Fragment />
	);
};

Modal.Footer = ModalFooter;
export default Modal;
